body {
  padding: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: white;
  margin: 0;
  /* overflow: hidden; */
  height: 100vh;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #5a8bff;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

.container {
  box-sizing: border-box;
  height: 100vh;
  padding: 32px;
}

.prejoin {
  display: grid;
  align-items: center;
  justify-items: center;
}

.prejoin main {
  width: 750px;
}

.prejoin h2 {
  text-align: center;
  margin-top: 30px;
}

.prejoin hr {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.prejoin footer {
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.entrySection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
  gap: 0.8rem;
}

@media only screen and (max-width: 800px) {
  .container {
    padding: 8px;
  }

  .entrySection {
    grid-template-columns: 1fr;
    margin-top: 20px;
    gap: 20px;
  }

  .prejoin main {
    width: 100%;
  }
}

.entrySection .label {
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 10px;
}

.entrySection input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  color: white;
  padding: 8px;
}

.entrySection input[type='text'] {
  width: calc(100% - 16px);
}

.entrySection div {
  vertical-align: middle;
}

.videoSection {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-top: 20px;
}

.videoSection video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.videoSection .placeholder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #2f2f2f;
}

.controlSection {
  margin-top: 30px;
  display: grid;
  grid-template-columns: auto auto minmax(100px, min-content);
}

.controlSection .right {
  grid-column: 3 / 4;
}

.roomContainer {
  flex: 1;
  height: calc(100vh - 100px);
}

.topBar {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
}

.topBar .right {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  gap: 16px;
}

.iconButton {
  cursor: pointer;
}

.iconButton:disabled {
  cursor: auto;
}

.participantCount {
  font-size: 14px;
}

.participantCount span {
  display: inline-block;
  margin-left: 5px;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, fit-content(120px)) auto;
  gap: 8px;
}

.options label {
  text-align: center;
  margin-left: 0.2rem;
  font-size: 0.9rem;
}

/*新增样式开始*/
.video-chat {
  display: flex;
  gap: 20px;
}

.chat-area {
  width: 320px;
}

.chat-area .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-area .title .close {
  width: 22px;
  height: 22px;
  line-height: 20px;
  font-size: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-align: center;
  display: none;
}

.chat-area .chat {
  height: calc(100vh - 277px);
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: auto;
  word-break: break-all;
}

.chat-area .send {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.chat-area .send .input {
  width: calc(100% - 60px);
  padding: 0 10px;
  font-size: 14px;
  color: #000;
  border: none;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.chat-btn {
  width: 52px;
  height: 38px;
  font-size: 14px;
  color: #000;
  background: #e0e012;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-btn.round {
  width: 64px;
  border-radius: 19px;
  margin: 0 6px;
  display: none;
}

.chat-btn:hover {
  background: #ff0;
}

@media screen and (max-width: 767px) {
  .chat-area {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    padding: 20px;
    box-sizing: border-box;
    background: black;
    display: none;
  }

  .chat-area .title .close {
    display: block;
  }

  .chat-btn.round {
    display: inline-block;
  }
}

@media screen and (min-width: 768px) {
  .chat-area {
    display: block !important;
  }
}

/*新增样式结束*/
